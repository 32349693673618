$pgLavender: #a037c5;
$pgIndigo: #1b46a1;
$pgPrimary: #005de7;
$pgLightBlue: #3eb5f0;
$pgCyan: #7ae5ef;
$pgLightGreen: #4bd5a0;
$pgGreen: #00ac69;
$pgDarkGreen: #1d7952;
$pgLightPurple: #8d65e0;
$pgPurple: #732b8f;
$pgMaroon: #6f0f46;
$pgRed: #a80d31;
$pgLightRed: #f24b4b;
$pgOrange: #ff6220;
$pgRust: #e8771d;
$pgSecondary: #567eb9;
$pgYellow: #ffcb69;

$pgSuccess: #009e78;
$pgDanger: #FF5029;
$pgWarning: #ffa82d;
$pgInfo: #48bdff;
$pgInfoAlt: #0d469b;

$primary: $pgPrimary;
$secondary: $pgSecondary;
$success: $pgSuccess;
$info: $pgInfo;
$danger: $pgDanger;
$warning: $pgWarning;

$primary-soft: mix($gray-100, $pgPrimary, 80%) !default;
$secondary-soft: mix($gray-100, $pgSecondary, 80%) !default;
$success-soft: mix($gray-100, $pgSuccess, 80%) !default;
$info-soft: mix($gray-100, $pgInfo, 80%) !default;
$warning-soft: mix($gray-100, $pgWarning, 80%) !default;
$danger-soft: mix($gray-100, $pgDanger, 80%) !default;

$pgIndigoGradS: #0E2B5D;
$pgIndigoGradE: #005DE7;

$pgBlueGradS: #005de7;
$pgBlueGradE: #48dbff;

$pgGreenGradS: #009e78;
$pgGreenGradE: #6aebc1;

$pgYellowGradS: #ffa82d;
$pgYellowGradE: #ffd788;

$pgOrangeGradS: #B73B06;
$pgOrangeGradE: #F97E07;

$pgRedGradS: #FF5029;
$pgRedGradE: #FFE1D4;

$pgMaroonGradS: #44060A;
$pgMaroonGradE: #C60C08;

$pgPurpleGradS: #3b0c9a;
$pgPurpleGradE: #813ADB;

$pgDarkGradS: #101722;
$pgDarkGradE: #54596e;

$pgLightGradS: #ffffff;
$pgLightGradE: #b0bdce;

// Dark Mode
$pgBlue-dark: #5da0e9;
$pgOrange-dark: #ffc561;
$pgSuccess-dark: #69cd52;
$pgDanger-dark: #ec7665;
$pgWarning-dark: #ffaf6f;
$pgInfo-dark: #b761dd;
$pgInfoAlt-dark: #53e2f1;

$pgBlueGradS-dark: #0e3693;
$pgBlueGradE-dark: #190254;

$pgOrangeGradS-dark: #93530e;
$pgOrangeGradE-dark: #93160e;

$pgGreenGradS-dark: #107d1f;
$pgGreenGradE-dark: #004e48;

$pgRedGradS-dark: #811129;
$pgRedGradE-dark: #5c0114;

$pgPurpleGradS-dark: #280f73;
$pgPurpleGradE-dark: #5f0f73;
